import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";

export const getOffersList = (skip, limit, data, offerRequestid) => {
  return Observable.create((observer: Observer<[]>) => {
    if (offerRequestid == null) {
      Axios.post<[]>(`${BASE_URL}/offer/offers-list?skip=${skip}&limit=${limit}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      }).subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
    }
    else {
      Axios.post<[]>(`${BASE_URL}/offer/offers-list?skip=${skip}&limit=${limit}`,
        {
          offerRequestId: offerRequestid
        }, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      }).subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
    }

  });
};

export const addOffer = (data: any) => {
  console.log("from offerlist.ts-->" + data)
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/offer/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const editOffers = (data: any, offerID: string) => {
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/offer/update?offerId=${offerID}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const associateOffer = (data: AnalyserNode) => {
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/offer/associate-offer`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const addOfferByAdmin = (data: any) => {
  // console.log("from offerlist.ts-->" + data)
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/offer/add-offer-adm`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}