import Axios from 'axios-observable';
import { Observable, Observer } from 'rxjs';
import { BASE_URL } from 'src/config';
import {Amenitiesformtype, EditAmenitiesformtype} from 'src/typings/amenities'


export const getAmenitiesList = () => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.get<[]>(
        `${BASE_URL}/amenity/amenities-list?skip=0&limit=10`
        ,
    {
      headers: {
        Authorization:`Bearer ${localStorage.accessToken}`,
      }
    },
       
        )
      .subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const addAmenities = (data: Amenitiesformtype) => {
    console.log(data)
    return Observable.create((observer: Observer<any>) => {
      Axios.post<any>(
        `${BASE_URL}/amenity/add`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`,
          }
        },
  
      )
        .subscribe(
          (resp) => {
            // console.log("Response", resp)
            observer.next(resp.data);
          },
          (error) => {
            let errorMsg: string = "";
            let errorCode: number = 0;
            if (error.request) {
              errorCode = error.response.data.statusCode;
              errorMsg = error.response.data.message;
              observer.error({ errorMsg, errorCode });
            }
          }
        )
    })
  }


  export const editAmenities = (data: EditAmenitiesformtype, amenityId: string) => {
    console.log(data)
    return Observable.create((observer: Observer<any>) => {
      Axios.post<any>(
        `${BASE_URL}/amenity/update/${amenityId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`,
          }
        },
  
      )
        .subscribe(
          (resp) => {
            // console.log("Response", resp)
            observer.next(resp.data);
          },
          (error) => {
            let errorMsg: string = "";
            let errorCode: number = 0;
            if (error.request) {
              errorCode = error.response.data.statusCode;
              errorMsg = error.response.data.message;
              observer.error({ errorMsg, errorCode });
            }
          }
        )
    })
  }
  
  