import Axios from 'axios-observable';
import { Observable, Observer } from 'rxjs';
import { BASE_URL } from 'src/config';
import {
  SendOTPDataType,
  ValidateOTPDataType,
  SendOTPResponseType,
  ValidateOTPDataResponseType,
} from 'src/typings/auth';
import { URL_IV, URL_KEY } from "../../src/config";
const CryptoJS = require("crypto-js");

const createdAt =  String(new Date().valueOf());
const password = `${URL_KEY}${createdAt}`;

export const getOtp = (data: SendOTPDataType) => {

  return Observable.create((observer: Observer<SendOTPResponseType>) => {
    Axios.post<SendOTPResponseType>(
      `${BASE_URL}/auth/send-otp-for-auth`,
      data,
      {
        headers: {
          accessKey: process.env.REACT_APP_ACCESS_KEY,
          createdAt:createdAt,
          apikey:CryptoJS.AES.encrypt(password, URL_KEY, { 
            iv: URL_IV 
          }).toString()
        }
      }).subscribe(
      (resp) => {
        console.log("resp", resp)
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = '';
        if (!error.request) {
          errorMsg = 'Shoot! Well this is unexpected. Our team is looking into it.';
          return observer.error({errorMsg});
        }
        const statusCode: number = error.response.data.statusCode;
        switch (statusCode) {
          case 404:
            errorMsg = error.response.data.message;
            break;
        }
        observer.error({ errorMsg });
      }
    )
  })
}

export const validateOtp = (data: ValidateOTPDataType) => {
  return Observable.create((observer: Observer<ValidateOTPDataResponseType>) => {
    Axios.post<ValidateOTPDataResponseType>(
      `${BASE_URL}/auth/validate-otp-for-auth`,
      data,
      {
       headers: {
          accessKey: process.env.REACT_APP_ACCESS_KEY,
          createdAt:createdAt,
          apikey:CryptoJS.AES.encrypt(password, URL_KEY, { 
            iv: URL_IV 
          }).toString()
        }
      },
    ).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = '';
        if (!error.request) {
          errorMsg = 'Shoot! Well this is unexpected. Our team is looking into it.';
        }
        const statusCode: number = error.response.data.statusCode;
        switch (statusCode) {
          case 403:
          case 404:
          case 500:
            errorMsg = error.response.data.message;
        }
        observer.error({ errorMsg });
      }
    )
  })
}
