import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";
console.log(localStorage.accessToken)
export const getOffersList = (skip, limit, shopId,data) => {
  return Observable.create((observer: Observer<[]>) => {
    if (shopId == null) {
      console.log("filter data-->")
      console.log(data)
      Axios.post<[]>(`${BASE_URL}/offer/fetch-offer-request?skip=${skip}&limit=${limit}`,data, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).subscribe(
        (resp) => {
          console.log("null executed")
          observer.next(resp.data);
          console.log(resp.data)
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
    }
    else {
      Axios.post<[]>(`${BASE_URL}/offer/fetch-offer-request?skip=${skip}&limit=${limit}`,
      {
        shopId:`${shopId}`
    }, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).subscribe(
        (resp) => {
          console.log(" without null executed")
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
    }
  });
};