import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";

export const eventRequestList = (skip, limit) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.get<[]>(`${BASE_URL}/event/admin-event-request-by-page?skip=${skip}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      }
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};
export const addEvent = (data: any) => {
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/event/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}
