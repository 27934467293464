import { Grid } from '@mui/material';
import { borderRadius } from '@mui/system';
import logo from 'src/images/fydo_logo.jpeg';

const Logo = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item>
        <img src={logo} alt="Lfyd" width="80px" height="80px" style={{borderRadius:"50%"}} />
      </Grid>
    </Grid>
  );
}

export default Logo;
