import Axios from 'axios-observable';
import { Observable, Observer } from 'rxjs';
import { BASE_URL } from 'src/config';

export const getNotificationList = (data, skip, limit) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.get<[]>(
      `${BASE_URL}/notification/scheduled-notifications?skip=${skip}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },


    )
      .subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const addNotification = (data: any) => {
  console.log(data)
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/notification/schedule-notification`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}
export const editNotification = (data: any, notificationId: string) => {
  console.log("edit categories-->")
  console.log(data)
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/notification/update-schedule-notification?id=${notificationId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          console.log("Response", resp.data)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}
export const getBanners = () => {
  return Observable.create((observer: Observer<any>) => {
    Axios.get<any>(
      `${BASE_URL}/area/v1/homepage-carousel`,

      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}
export const AddBanner = (data: any) => {
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/area/admin-edit-homepage-carousel`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}