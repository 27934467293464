import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";
import { EventType, SearchEventsformtype } from "src/typings/event";

export const getREvent = (data: SearchEventsformtype, skip, limit) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.get<[]>(
      `${BASE_URL}/event/fetch-recurring-events-by-page?skip=${skip}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
    ).subscribe(
      (resp) => {
          console.log('resp--',resp)
        observer.next(resp.data);

      },
      (error) => {
        let errorMsg: string = "";
        if (error.request) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};

export const getEventDates = (data: any, EventId: string | object) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.get<[]>(
      `${BASE_URL}/event/fetch-dates?eventId=${EventId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
    ).subscribe(
      (resp) => {
          console.log('resp--',resp)
        observer.next(resp.data);

      },
      (error) => {
        let errorMsg: string = "";
        if (error.request) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};
export const getActiveEventDates = (data: any, EventId: string | object) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.get<[]>(
      `${BASE_URL}/event/fetch-active-dates?eventId=${EventId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
    ).subscribe(
      (resp) => {
          console.log('resp--',resp)
        observer.next(resp.data);

      },
      (error) => {
        let errorMsg: string = "";
        if (error.request) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};


export const updateEventDate = (data: any, EventDatesId: string | object) => {
  return Observable.create((observer: Observer<any>) => {
   
    Axios.post<any>(`${BASE_URL}/event/modify-date?id=${EventDatesId}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        console.log("Error", error);
        let errorMsg: string = "";
        if (error.request) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};

export const addEventDate = (data: any) => {
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/event/add-date`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const getEventName = (data: SearchEventsformtype, skip,limit) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(
      `${BASE_URL}/event/get-data-filter?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },
     

    )
      .subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}
