import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";
console.log(localStorage.accessToken)
export const getStoreList = (skip, limit, data) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<any>(`${BASE_URL}/shop/shops-list?skip=${skip}&limit=${limit}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};

export const getStoreDetail = (storeId: string) => {
  return Observable.create((observer: Observer<any>) => {
    Axios.get<any>(`${BASE_URL}/shop?shopId=${storeId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};

export const storeApprove = (data: any) => {
  console.log(data);
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(`${BASE_URL}/shop/admin-update?shopId=${data._id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        ;
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};


export const rejectStore = (data: any) => {
  console.log('Data to', data)
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(`${BASE_URL}/shop/admin-update?shopId=${data._id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        console.log("Response", resp);
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};

export const updateHistory = (skip,limit,data) => {
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(`${BASE_URL}/update-history/v1/fetch-by-entity?skip=${skip}&limit=${limit}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        console.log("Response", resp);
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};
