import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";
export const getStoreCount = (data) => {
    return Observable.create((observer: Observer<[]>) => {
      Axios.post<any>(`${BASE_URL}/shop/shops-overview`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).subscribe(
        (resp) => {
          observer.next(resp.data);
          
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
    });
  };

export const getTotalWalletAndSelfDetail = (startTime,transactionPurpose,showLinkedTransaction,endTime,shopId) => {
  if (shopId!=null) {
    return Observable.create((observer: Observer<[]>) => {
      Axios.get<any>(`${BASE_URL}/transaction/fetchTotalWalletAndSelf?startTime=${startTime}&transactionPurpose=${transactionPurpose}&showLinkedTransaction=${showLinkedTransaction}&endTime=${endTime}&shopId=${shopId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).subscribe(
        (resp) => {
          observer.next(resp.data);
          
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
    });
  } else{
    return Observable.create((observer: Observer<[]>) => {
      Axios.get<any>(`${BASE_URL}/transaction/fetchTotalWalletAndSelf?startTime=${startTime}&transactionPurpose=${transactionPurpose}&showLinkedTransaction=${showLinkedTransaction}&endTime=${endTime}`, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).subscribe(
        (resp) => {
          observer.next(resp.data);
          
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
    });
  }
    
  };