import Axios from 'axios-observable';
import { Observable, Observer } from 'rxjs';
import { BASE_URL } from 'src/config';
import { Categoriesformtype, EditCategoriesformtype, SearchCategoriesformtype } from 'src/typings/categories'

export const getCategoriesList = (data: SearchCategoriesformtype, skip,limit) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(
      `${BASE_URL}/category/categories-list?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },
     

    )
      .subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const addCategories = (data: Categoriesformtype) => {
  console.log(data)
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/category/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const editCategories = (data: EditCategoriesformtype, categoryId: string) => {
  console.log("edit categories-->")
  console.log(data)
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/category/update/${categoryId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          console.log("Response", resp.data)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const searchCategoriesList = (data: SearchCategoriesformtype, skip,limit) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(
      `${BASE_URL}/category/categories-list?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },
     

    )
      .subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error;
            observer.error({ errorMsg, errorCode});
          }
        }
      )
  })
}


