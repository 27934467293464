import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";

export const getTransactionsList = (skip, limit, data) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(`${BASE_URL}/transaction/admin/fetch-transactions?skip=${skip}&limit=${limit}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      }
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};
export const paymentDetail = (skip, limit, data) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(`${BASE_URL}/shop-payment/payment-details?skip=${skip}&limit=${limit}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      }
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};

export const paymentDetailById = (data)=>{
  return Observable.create((observer: Observer<[]>) => {
    Axios.get<[]>(`${BASE_URL}/shop-payment/payment-details-by-id?id=${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      }
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
}


export const getSettlementsList = (skip, limit, data) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(`${BASE_URL}/transaction/shops-settlement-overview?skip=${skip}&limit=${limit}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      }
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};

export const getWalletList = (skip, limit, data) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(`${BASE_URL}/wallet/admin/fetch-wallet-balance?skip=${skip}&limit=${limit}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      }
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};
export const updatePaymentDetail = (data, id) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(`${BASE_URL}/shop-payment/update-shop-payment-details/?shopId=${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      }
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};
export const ChangeRefundStatus = (id) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(`${BASE_URL}/transaction/setRefundPendingFalse/${id}`, 1, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      }
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};

export const addDocuments = (data: any) => {
  // console.log("from offerlist.ts-->" + data)
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/shop-payment/add-shop-payment-documents`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },

    )
      .subscribe(
        (resp) => {
          // console.log("Response", resp)
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

export const ChangePaymentStatus = (paymentstatus: { shopId: string; paymentEnabled: boolean }) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(`${BASE_URL}/shop/update-payment-status-admin`, paymentstatus, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      }
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};