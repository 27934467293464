import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";
console.log(localStorage.accessToken)
export const getStoreList = (skip, limit, data) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<any>(`${BASE_URL}/shop-admin/shops-referral-details?skip=${skip}&limit=${limit}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};