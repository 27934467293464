import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";
import { SearchEventsformtype } from "src/typings/event";
//import { OrganizerType } from "src/typings/event";
export const getOrganizer = (data: SearchEventsformtype, skip,limit) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(
      `${BASE_URL}/organiser/paginated-organiser-admin?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        }
      },
     

    )
      .subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.request) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      )
  })
}

// export const getOrganizer = (filter: any, skip: number, limit: number) => {
  
//   return Observable.create((observer: Observer<any>) => {
//     Axios.get(
//       `${BASE_URL}/organiser/paginated-organiser?skip=${skip}&limit=${limit}`,
//       {
//         headers: {
//           Authorization: `Bearer ${localStorage.accessToken}`,
//           'Content-Type': 'application/json',
//         },
//         params: { ...filter, skip, limit },
//         // data: {
//         //   filter: {
//         //     mobile: '7984498992',
//         //     organiserId: '63665107ae05e2ab7fe29800',
//         //   },
//         // },
//       }
//     ).subscribe(
//       (resp) => {
//         console.log('resp--', resp);
//         observer.next(resp.data);
//         observer.complete(); 
//       },
//       (error) => {
//         let errorMsg: string = '';
//         if (error.response) {
//           errorMsg = error.response.data.message;
//         } else if (error.request) {
//           errorMsg = 'Request made but no response received';
//         } else {
//           errorMsg = error.message;
//         }
//         observer.error({ errorMsg });
//       }
//     );
//   });
// };
export const updateOrCreateOrganizer = (data: any) => {
  return Observable.create((observer: Observer<any>) => {
    const mobile = data.mobile; 
    Axios.post<any>(`${BASE_URL}/organiser/admin-add-or-update?mobile=${mobile}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
        'Content-Type': 'application/json',
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
        observer.complete(); 
      },
      (error) => {
        console.log("Error", error);
        let errorMsg: string = "";
        if (error.response) {
          errorMsg = error.response.data.message;
        } else if (error.request) {
          errorMsg = 'Request made but no response received';
        } else {
          errorMsg = error.message;
        }
        observer.error({ errorMsg });
      }
    );
  });
};



// export const getOrganizer = (data: any, skip, limit) => {
//   return Observable.create((observer: Observer<OrganizerType>) => {
//     Axios.get<OrganizerType>(
//       // `http://localhost:8080/organiser/paginated-organiser??skip=${skip}&limit=${limit}`,
//        `${BASE_URL}/organiser/paginated-organiser?skip=${skip}&limit=${limit}`,
//       {
//         headers: {
//           Authorization: `Bearer ${localStorage.accessToken}`,
//         },
//         data: {
//           filter: {
//             mobile: '7984498992',
//             organiserId: '63665107ae05e2ab7fe29800',
//           },
//       }
//     ).subscribe(
//       (resp) => {
//           console.log('resp--',resp)
//         observer.next(resp.data);

//       },
//       (error) => {
//         let errorMsg: string = "";
//         if (error.request) {
//           errorMsg = error.response.data.message;
//           observer.error({ errorMsg });
//         }
//       }
//     );
//   });
// };

// export const updateOrganizer = (data: OrganizerType, OrganizerId: string | object) => {
//   return Observable.create((observer: Observer<any>) => {
   
//     Axios.post<any>(`${BASE_URL}/organizer/edit?id=${OrganizerId}`, data, {
//       headers: {
//         Authorization: `Bearer ${localStorage.accessToken}`,
//       },
//     }).subscribe(
//       (resp) => {
//         observer.next(resp.data);
//       },
//       (error) => {
//         console.log("Error", error);
//         let errorMsg: string = "";
//         if (error.request) {
//           errorMsg = error.response.data.message;
//           observer.error({ errorMsg });
//         }
//       }
//     );
//   });
// };
export const addOrganizer = (data: any) => {
    return Observable.create((observer: Observer<any>) => {
      Axios.post<any>(
        `${BASE_URL}/organizer/add`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`,
          }
        },
  
      )
        .subscribe(
          (resp) => {
            // console.log("Response", resp)
            observer.next(resp.data);
          },
          (error) => {
            let errorMsg: string = "";
            let errorCode: number = 0;
            if (error.request) {
              errorCode = error.response.data.statusCode;
              errorMsg = error.response.data.message;
              observer.error({ errorMsg, errorCode });
            }
          }
        )
    })
  }