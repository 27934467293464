import { useRoutes } from "react-router-dom";
import routes from "./router";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline } from "@mui/material";

const App = () => {
  const checkAuth = () => {
    return localStorage.accessToken ? true : false;
  };
  const content = useRoutes(routes(checkAuth()));

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
