import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";
import { URL_IV, URL_KEY } from "../../src/config";
const CryptoJS = require("crypto-js");
// import instance from "src/utils/instance";

export const getshopSettlementList = (skip, limit, data) => {
  const createdAt = String(new Date().valueOf());
  const password = `${URL_KEY}${createdAt}`;
  return Observable.create(async (observer: Observer<[]>) => {
      Axios.post<[]>(
      `${BASE_URL}/shop-settlement/fetch-merchant-settlements?skip=${skip}&limit=${limit}`,data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
          createdAt:createdAt,
          apikey:CryptoJS.AES.encrypt(password, URL_KEY, {
      iv: URL_IV
    }).toString()
        }
      },
    ).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};