import Axios from "axios-observable";
import { Observable, Observer, from } from "rxjs";
import { BASE_URL } from "src/config";
import {
  addCategoriesformtype,
  Categoriesformtype,
  EditCategoriesformtype,
  SearchCategoriesformtype,
} from "src/typings/event";

export const getCategoriesList = (
  data: SearchCategoriesformtype,
  skip,
  limit
) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(
      `${BASE_URL}/event/category/all?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
    ).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};
// export const getCategoriesList = (
//   data: SearchCategoriesformtype,
//   skip: number,
//   limit: number
// ) => {
//   return Observable.create((observer: Observer<[]>) => {
//     Axios.post<[]>(
//       `${BASE_URL}/event/category/all?skip=${skip}&limit=${limit}`,
//       {
//         headers: {
//           Authorization: `Bearer ${localStorage.accessToken}`,
//         },
//       }
//     ).subscribe(
//       (resp) => {
//         console.log("resp--", resp);
//         observer.next(resp.data);
//       },
//       (error) => {
//         let errorMsg: string = "";
//         if (error.request) {
//           errorMsg = error.response.data.message;
//           observer.error({ errorMsg });
//         }
//       }
//     );
//   });
// };
export const addCategories = (data: any) => {
  console.log(data);
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(`${BASE_URL}/event/category/add`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        // console.log("Response", resp)
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};
export const searchCategoriesList = (
  data: SearchCategoriesformtype,
  skip,
  limit
) => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(
      `${BASE_URL}/category/all?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
    ).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};
export const getEventCategoryName = () => {
  return Observable.create((observer: Observer<[]>) => {
    Axios.get<[]>(`${BASE_URL}/event/categoryname/all`,  {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorCode = error.response.data.statusCode;
          errorMsg = error.response.data.message;
          observer.error({ errorMsg, errorCode });
        }
      }
    );
  });
};

export const editCategories = (
  data: Categoriesformtype,
  categoryId: string | object
) => {
  console.log("edit categories-->");
  console.log(data);
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/event/category/update?id=${categoryId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
    ).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        console.log("Error", error);
        let errorMsg: string = "";
        if (error.request) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};

export const deleteCategories = (categoryId: string) => {
  return new Observable((observer) => {
    from(
      Axios.delete(`${BASE_URL}/event/category/delete?id=${categoryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      })
    ).subscribe(
      (resp) => {
        observer.next(resp.data);
        observer.complete();
      },
      (error) => {
        console.log("Error", error);
        let errorMsg: string = "";
        if (error.request) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};
