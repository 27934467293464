import * as auth from './auth';
import * as brand from './brand';
import * as store from './store'
import * as amenities from './amenities'
import * as categories from './categories'
import * as utils from './utils'
import * as offers from './offers'
import * as offerList from './offerList'
import * as overview from './overview'
import * as feedback from './feedback'
import * as appStore from './appStore'
import * as referal from './referal'
import * as notification from './notification'
import * as payments from './payments'
import * as eventRequest from './eventRequest'
import * as event from './event'
import * as eventCategories from './eventCategories'
import * as organizer from './organizer'
import * as shopSettlement from './shopSettlement'
import * as eventDates from './eventDates'
export default {
    auth,
    brand,
    store,
    amenities,
    categories,
    utils,
    offers,
    offerList,
    overview,
    feedback,
    appStore,
    referal,
    notification,
    payments,
    eventRequest,
    event,
    eventCategories,
    eventDates,
    organizer,
    shopSettlement,
};