import Axios from "axios-observable";
import { Observable, Observer } from "rxjs";
import { BASE_URL } from "src/config";
import {
  BrandDto,
  addBrandResponseType,
  SearchBrandformtype,
} from "src/typings/brand";
import { BrandFormType } from "src/content/brand/addBrands";

export const getBrands = (data: SearchBrandformtype, skip, limit) => {
  return Observable.create((observer: Observer<BrandDto[]>) => {
    Axios.post<BrandDto[]>(
      `${BASE_URL}/brand/v1?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
    ).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        if (error.request) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};

export const addBrand = (data: BrandFormType) => {
  console.log(data);
  return Observable.create((observer: Observer<addBrandResponseType>) => {
    Axios.post<addBrandResponseType>(`${BASE_URL}/brand/add`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        console.log("Respponse", resp);
        observer.next(resp.data);
      },
      (error) => {
        console.log("Error", error);
        let errorMsg: string = "";
        if (error.request) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};

export const updateBrand = (data: BrandFormType, brandId: string | object) => {
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(`${BASE_URL}/brand/update/${brandId}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        console.log("Error", error);
        let errorMsg: string = "";
        if (error.request) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};

export const searchBrands = (data: SearchBrandformtype, skip, limit) => {
  return Observable.create((observer: Observer<BrandDto[]>) => {
    Axios.post<BrandDto[]>(
      `${BASE_URL}/brand/v1?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
    ).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        let errorMsg: string = "";
        let errorCode: number = 0;
        if (error.request) {
          errorMsg = error;
          // errorCode = error.response.data.statusCode;
          observer.error({ errorMsg });
        }
      }
    );
  });
};
